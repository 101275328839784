import React, { useEffect, useState } from "react";
import { fetchedAllPaymentsByOperation } from "../../../apis/apis.accounting/apisCash";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

export function CollectionSheetList({ sales, setCashFlow, setCashFlowSet }) {
    const [filename, setFilename] = useState("ReportePagos");
    const [summary, setSummary] = useState({
        sumTotalPurchased: 0,
        sumTotalPaid: 0,
        sumTotalPaidInCash: 0,
        sumTotalPaidInYape: 0,
        sumTotalPending: 0,
    });

    useEffect(() => {
        calculateTotal();
    }, [sales]);

    const getAllPayments = async (operationID) => {
        try {
            const response = await fetchedAllPaymentsByOperation({
                operationID: operationID,
            });
            setCashFlowSet(response);
        } catch (error) {
            throw error;
        }
    };

    function calculateTotal() {
        const sumTotalPurchased = sales
            ?.filter((item) => item.operationStatus === "02")
            .reduce((total, operation) => {
                return total + Number(operation.totalPurchased);
            }, 0);

        const sumTotalPaid = sales
            ?.filter((item) => item.operationStatus === "02")
            .reduce((total, operation) => {
                return total + Number(operation.totalPaid);
            }, 0);

        const sumTotalPaidInCash = sales
            ?.filter((item) => item.operationStatus === "02")
            .reduce((total, operation) => {
                return total + Number(operation.totalPaidInCash);
            }, 0);

        const sumTotalPaidInYape = sales
            ?.filter((item) => item.operationStatus === "02")
            .reduce((total, operation) => {
                return total + Number(operation.totalPaidInYape);
            }, 0);

        const sumTotalPending = sales
            ?.filter((item) => item.operationStatus === "02")
            .reduce((total, operation) => {
                return total + Number(operation.totalPending);
            }, 0);

        setSummary((prevEntry) => ({
            ...prevEntry,
            sumTotalPurchased: sumTotalPurchased,
            sumTotalPaid: sumTotalPaid,
            sumTotalPaidInCash: sumTotalPaidInCash,
            sumTotalPaidInYape: sumTotalPaidInYape,
            sumTotalPending: sumTotalPending,
        }));
    }

    return (
        <>
            <table
                className="table table-sm table-bordered text-uppercase"
                id="table-to-xls"
            >
                <thead>
                    <tr className="bg-gradient-secondary">
                        <td
                            colSpan={1}
                            className="align-middle text-center text-uppercase"
                        >
                            ID
                        </td>
                        <td
                            colSpan={1}
                            className="align-middle text-center text-uppercase"
                        >
                            FECHA
                        </td>
                        <td
                            colSpan={1}
                            className="align-middle text-center text-uppercase"
                        >
                            HORA
                        </td>
                        <td
                            colSpan={1}
                            className="align-middle text-center text-uppercase"
                        >
                            USUARIO
                        </td>
                        <td
                            colSpan={1}
                            className="align-middle text-center text-uppercase"
                        >
                            CODIGO
                        </td>
                        <td
                            colSpan={1}
                            className="align-middle text-center text-uppercase"
                        >
                            CLIENTE
                        </td>
                        <td
                            colSpan={1}
                            className="align-middle text-center text-uppercase"
                        >
                            VISITA
                        </td>
                        <td
                            colSpan={1}
                            className="align-middle text-center text-uppercase"
                        >
                            RUTA
                        </td>
                        <td
                            colSpan={1}
                            className="align-middle text-center text-uppercase"
                        >
                            DIRECCIÓN
                        </td>
                        <td
                            colSpan={1}
                            className="align-middle text-center text-uppercase"
                        >
                            SERIE
                        </td>
                        <td
                            colSpan={1}
                            className="align-middle text-center text-uppercase"
                        >
                            NUM
                        </td>
                        <td
                            colSpan={1}
                            className="align-middle text-center text-uppercase"
                        >
                            COMPRADO
                        </td>
                        <td
                            colSpan={1}
                            className="align-middle text-center text-uppercase"
                        >
                            PAGADO
                        </td>
                        <td
                            colSpan={1}
                            className="align-middle text-center text-uppercase"
                        >
                            PENDIENTE
                        </td>
                        <td
                            colSpan={1}
                            className="align-middle text-center text-uppercase"
                        ></td>
                    </tr>
                </thead>
                <tbody>
                    {sales?.map((item, index) => {
                        return (
                            <tr
                                key={index}
                                value={item.operationID}
                                className={
                                    item.operationStatus == "03"
                                        ? "bg-danger"
                                        : item.operationType == "01"
                                        ? "bg-warning"
                                        : item.operationType == "02"
                                        ? "bg-light"
                                        : "text-dark"
                                }
                            >
                                <td className="align-middle text-sm">
                                    {item.operationID}
                                </td>
                                <td className="align-middle text-sm">
                                    {item.registerDate}
                                </td>
                                <td className="align-middle text-sm">
                                    {item.registerTime}
                                </td>
                                <td className="align-middle text-sm">
                                    {`${item.userName?.substring(0, 5)}...`}
                                </td>
                                <td className="align-middle text-sm">
                                    {item.clientObservation}
                                </td>
                                <td className="align-middle text-sm">
                                    {item.clientName}
                                </td>
                                <td className="align-middle text-sm">
                                    {item.clientVisitDayDisplay}
                                </td>
                                <td className="align-middle text-sm">
                                    {item.clientGangName}
                                </td>
                                <td className="align-middle text-sm">
                                    {item.clientAddress}
                                </td>
                                <td className="align-middle text-sm">
                                    {item.receiptSerial}
                                </td>
                                <td className="align-middle text-sm">
                                    {item.receiptNumber}
                                </td>
                                <td className="align-middle text-sm text-right text-nowrap">
                                    {item.totalPurchased
                                        ? `S/ ${Number(
                                              item.totalPurchased
                                          ).toFixed(2)}`
                                        : "S/ 0.00"}
                                </td>
                                <td className="align-middle text-sm text-right text-nowrap">
                                    {item.totalPaid
                                        ? `S/ ${Number(item.totalPaid).toFixed(
                                              2
                                          )}`
                                        : "S/ 0.00"}
                                </td>
                                <td className="align-middle text-sm text-right text-nowrap">
                                    {item.totalPending
                                        ? `S/ ${Number(
                                              item.totalPending
                                          ).toFixed(2)}`
                                        : "S/ 0.00"}
                                </td>
                                <td>
                                    {item.operationStatus === "02" &&
                                    Number(item.totalPending) > 0 ? (
                                        <button
                                            type="button"
                                            onClick={() => {
                                                setCashFlow((prevCashFlow) => ({
                                                    ...prevCashFlow,
                                                    description: "",
                                                    type: "E",
                                                    operationID: Number(
                                                        item.operationID
                                                    ),
                                                    total: Number(
                                                        item.totalPending
                                                    ),
                                                }));
                                                getAllPayments(
                                                    item.operationID
                                                );
                                            }}
                                            data-toggle="modal"
                                            data-target="#collectModal"
                                            className="btn btn-sm bg-red w-100"
                                        >
                                            PAGAR
                                        </button>
                                    ) : null}
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td
                            className="align-middle text-sm text-right"
                            colSpan={11}
                        >
                            TOTAL
                        </td>
                        <td className="align-middle text-sm text-right text-nowrap">
                            {`S/ ${Number(summary.sumTotalPurchased).toFixed(
                                2
                            )}`}
                        </td>
                        <td className="align-middle text-sm text-right text-nowrap">
                            {`S/ ${Number(summary.sumTotalPaid).toFixed(2)}`}
                        </td>
                        <td className="align-middle text-sm text-right text-nowrap">
                            {`S/ ${Number(summary.sumTotalPending).toFixed(2)}`}
                        </td>
                        <td></td>
                    </tr>
                    <tr>
                        <td
                            className="align-middle text-sm text-right"
                            colSpan={11}
                        >
                            EFECTIVO
                        </td>
                        <td className="align-middle text-sm text-right text-nowrap"></td>
                        <td className="align-middle text-sm text-right text-nowrap">
                            {`S/ ${Number(summary.sumTotalPaidInCash).toFixed(
                                2
                            )}`}
                        </td>
                        <td className="align-middle text-sm text-right text-nowrap"></td>
                        <td></td>
                    </tr>
                    <tr>
                        <td
                            className="align-middle text-sm text-right"
                            colSpan={11}
                        >
                            YAPE
                        </td>
                        <td className="align-middle text-sm text-right text-nowrap"></td>
                        <td className="align-middle text-sm text-right text-nowrap">
                            {`S/ ${Number(summary.sumTotalPaidInYape).toFixed(
                                2
                            )}`}
                        </td>
                        <td className="align-middle text-sm text-right text-nowrap"></td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>

            <ReactHTMLTableToExcel
                id="table-xls-button"
                className="btn btn-outline-secondary mt-3 ml-1"
                table="table-to-xls"
                filename={filename}
                sheet="report"
                buttonText="Descargar como XLS"
            />
        </>
    );
}
