import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { ContextAuth } from "../../../contexts/ContextAuth";

export function FragmentNavigation() {
  const { auth } = useContext(ContextAuth);
  const [isSupervisor, setIsSupervisor] = useState(false);
  const [isAdministrator, setIsAdministrator] = useState(false);
  const [isSeller, setIsSeller] = useState(false);
  useEffect(() => {
    if (auth.data.groups.some((item) => item.name === "Administrativo"))
      setIsAdministrator(true);
    else if (auth.data.groups.some((item) => item.name === "Vendedor"))
      setIsSeller(true);
    else if (auth.data.groups.some((item) => item.name === "Supervisor"))
      setIsSupervisor(true);
    // console.log(auth.data.groups)
  }, []);
  return (
    <aside className="main-sidebar sidebar-light-primary elevation-4">
      {/* Brand Logo */}

      <Link to="/" className="brand-link">
        <img
          src="/dist/img/newlogo.png"
          alt="AdminLTE Logo"
          className="brand-image img-circle elevation-3"
          style={{ opacity: ".8" }}
        />
        <span className="brand-text font-weight-light small text-right">
          DIST. "DELDIA"
        </span>
      </Link>

      {/* Sidebar */}
      <div className="sidebar">
        {/* Sidebar Menu */}
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}

            <li className="nav-item">
              <div className="accordion" id="accordionExample">
                {isAdministrator ? (
                  <div className="card mb-0">
                    <div className="card-header p-0" id="headingOne">
                      <a
                        href="#"
                        className="nav-link bg-light"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        <i className="nav-icon fas fa-th  text-primary" />
                        <p className=" text-primary">
                          LOGISTICA<i className="right fas fa-angle-right"></i>
                        </p>
                      </a>
                    </div>

                    <div
                      id="collapseOne"
                      className="collapse show"
                      aria-labelledby="headingOne"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body p-0 ">
                        <Link to="/logistics/products" className="nav-link">
                          <i className="nav-icon fas fa-cube"></i>
                          <p>Productos</p>
                        </Link>

                        <Link
                          to="/logistics/product_brands"
                          className="nav-link"
                        >
                          <i className="nav-icon fas fa-sitemap" />
                          <p>Marcas y Unidades</p>
                        </Link>
                        <Link to="/logistics/warehouses" className="nav-link">
                          <i className="nav-icon fas fa-cubes"></i>
                          <p>Almacenes</p>
                        </Link>
                        <Link to="/logistics/inventories" className="nav-link">
                          <i className="nav-icon fas fa-globe"></i>
                          <p>Mov. Kardex</p>
                        </Link>
                        <Link to="/logistics/matrix" className="nav-link">
                          <i className="nav-icon fas fa-cube"></i>
                          <p>Matriz de productos</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {isAdministrator ? (
                  <div className="card mb-0">
                    <div className="card-header p-0" id="headingTwo">
                      <a
                        href="#"
                        className="nav-link bg-light"
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        <i className="nav-icon fas fa-copy  text-primary" />
                        <p className="text-primary">
                          FINANZAS<i className="right fas fa-angle-right"></i>
                        </p>
                      </a>
                    </div>
                    <div
                      id="collapseTwo"
                      className="collapse show"
                      aria-labelledby="headingTwo"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body p-0">
                        <Link
                          to="/accounting/cash_control"
                          className="nav-link"
                        >
                          <i className="nav-icon fas fa-cash-register" />
                          <p>Control de dinero en efectivo</p>
                        </Link>
                        <Link to="/accounting/banks" className="nav-link">
                          <i className="nav-icon fas fa-piggy-bank" />

                          <p>Control de dinero en Bancos</p>
                        </Link>

                        <Link
                          to="/accounting/accountReceive"
                          className="nav-link"
                        >
                          <i className="nav-icon fas fa-file-invoice-dollar"></i>
                          <p>Control de creditos</p>
                        </Link>

                        <Link to="/accounting/accountPay" className="nav-link">
                          <i className="nav-icon fas fa-file-invoice-dollar"></i>
                          <p>Cuentas por Pagar</p>
                        </Link>

                        <Link to="/accounting/CollectionSheetPage" className="nav-link">
                        <i className="nav-icon fas fa-cube"></i>
                          <p>Hoja de cobranza</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {isAdministrator ? (
                  <div className="card mb-0">
                    <div className="card-header p-0" id="headingThree">
                      <a
                        href="#"
                        className="nav-link bg-light"
                        data-toggle="collapse"
                        data-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        <i className="nav-icon fas fa-users-cog text-primary" />
                        <p className=" text-primary">
                          RRHH<i className="right fas fa-angle-right"></i>
                        </p>
                      </a>
                    </div>
                    <div
                      id="collapseThree"
                      className="collapse"
                      aria-labelledby="headingThree"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body p-0">
                        <Link to="/employees" className="nav-link">
                          <i className="nav-icon fas fa-user-astronaut" />
                          <p>Empleados</p>
                        </Link>
                        <Link to="/subsidiaries" className="nav-link">
                          <i className="nav-icon fas fa-house-damage" />
                          <p>Sucursal</p>
                        </Link>
                        <Link to="/gangs" className="nav-link">
                          <i className="nav-icon fas fa-id-card-alt" />
                          <p>Rutas / Cuadrillas</p>
                        </Link>
                        <Link to="/epps" className="nav-link">
                          <i className="nav-icon fas fa-user-lock" />
                          <p>EPPS</p>
                        </Link>
                        <a href="pages/examples/500.html" className="nav-link">
                          <i className="far fa-circle nav-icon" />
                          <p>Error 500</p>
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {isAdministrator ? (
                  <div className="card mb-0">
                    <div className="card-header p-0" id="headingFour">
                      <a
                        href="#"
                        className="nav-link bg-light"
                        data-toggle="collapse"
                        data-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        <i className="nav-icon fas fa-shopping-cart text-primary" />
                        <p className="text-primary">
                          COMPRAS<i className="right fas fa-angle-right"></i>
                        </p>
                      </a>
                    </div>
                    <div
                      id="collapseFour"
                      className="collapse"
                      aria-labelledby="headingFour"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body p-0">
                        <Link
                          to="/buys/purchase_orders_issued"
                          className="nav-link"
                        >
                          <i className="nav-icon fas fa-search"></i>
                          <p>Busqueda de pedidos</p>
                        </Link>
                        <Link
                          to="/buys/new_purchase_order"
                          className="nav-link"
                        >
                          <i className="nav-icon fas fa-map"></i>
                          <p>Registrar pedido</p>
                        </Link>

                        <Link to="/buys/purchases_issued" className="nav-link">
                          <i className="nav-icon fas fa-cart-arrow-down" />
                          <p>Compras recibidas</p>
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {isAdministrator || isSupervisor ? (
                  <div className="card mb-0">
                    <div className="card-header p-0" id="headingFive">
                      <a
                        href="#"
                        className="nav-link bg-light"
                        data-toggle="collapse"
                        data-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        <i className="nav-icon fas fa-truck text-primary" />
                        <p className="text-primary">
                          VENTAS Y ABAST.
                          <i className="right fas fa-angle-right"></i>
                        </p>
                      </a>
                    </div>
                    <div
                      id="collapseFive"
                      className="collapse"
                      aria-labelledby="headingFive"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body p-0">
                        {isAdministrator ? (
                          <>
                            <Link
                              to="/dispatches/distributions"
                              className="nav-link"
                            >
                              <i className="nav-icon fas fa-paper-plane"></i>
                              <p>Ventas de reparto</p>
                            </Link>

                            <Link
                              to="/dispatches/supplyings"
                              className="nav-link"
                            >
                              <i className="nav-icon fas fa-share"></i>
                              <p>Abastecimiento</p>
                            </Link>
                            <Link
                              to="/dispatches/devolutions"
                              className="nav-link"
                            >
                              <i className="nav-icon fas fa-reply"></i>
                              <p>Devoluciones</p>
                            </Link>
                            <Link
                              to="/dispatches/readjustments"
                              className="nav-link"
                            >
                              <i className="nav-icon fas fa-screwdriver"></i>
                              <p>Reajustes de almacen</p>
                            </Link>
                            <Link
                              to="/dispatches/accumulative_dispatches"
                              className="nav-link"
                            >
                              <i className="nav-icon fas fa-globe"></i>
                              <p>Ventas Acumuladas</p>
                            </Link>
                            <Link
                              to="/dispatches/map_clients"
                              className="nav-link"
                            >
                              <i className="nav-icon fas fa-map-marked-alt"></i>
                              <p>Ubicacion de clientes</p>
                            </Link>
                            <Link
                              to="/logistics/merchandises"
                              className="nav-link"
                            >
                              <i className="nav-icon fas fa-cubes"></i>
                              <p>Mercaderia por repartidor</p>
                            </Link>

                            <Link
                              to="/dispatches/order_tray"
                              className="nav-link"
                            >
                              <i className="nav-icon fas fa-screwdriver"></i>
                              <p>Bandeja de pedidos</p>
                            </Link>

                            <Link
                              to="/dispatches/special_sales"
                              className="nav-link"
                            >
                              <i className="nav-icon fas fa-paper-plane"></i>
                              <p>Ventas especiales</p>
                            </Link>
                          </>
                        ) : (
                          ""
                        )}

                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}




                {isAdministrator || isSupervisor ? (
                  <div className="card mb-0">
                    <div className="card-header p-0" id="headingSix">
                      <a
                        href="#"
                        className="nav-link bg-light"
                        data-toggle="collapse"
                        data-target="#collapseSix"
                        aria-expanded="false"
                        aria-controls="collapseSix"
                      >
                        {/* <i className="nav-icon fas fa-truck text-primary" /> */}
                        <i className="nav-icon fas fa-chart-line text-primary"></i>
                        {/* <i className="nav-icon fas fa-file  text-primary"></i> */}
                        <p className="text-primary">
                          REPORTES
                          <i className="right fas fa-angle-right"></i>
                        </p>
                      </a>
                    </div>
                    <div
                      id="collapseSix"
                      className="collapse"
                      aria-labelledby="headingSix"
                      data-parent="#accordionExample"
                    >
                      <div className="card-body p-0">
                        
                        {isAdministrator || isSupervisor ? (
                          <>
                            <Link
                              to="/dispatches/sales_statistics"
                              className="nav-link"
                            >
                              <i className="nav-icon fas fa-chart-bar"></i>
                              <p>Ventas por semana</p>
                            </Link>
                            <Link
                              to="/dispatches/daily_route_graph_by_day"
                              className="nav-link"
                            >
                              <i className="nav-icon fas fa-chart-pie"></i>
                              <p>Ventas por ruta</p>
                            </Link>
                            
                            <Link
                              to="/dispatches/sold_product_graph_by_day"
                              className="nav-link"
                            >
                              <i className="nav-icon fas fa-chart-area"></i>
                              <p>Ventas de productos</p>
                            </Link>

                            <Link
                              to="/dispatches/client_portfolios"
                              className="nav-link"
                            >
                              {/* <i className="nav-icon fas fa-chart-area"></i> */}
                              <i className="nav-icon fas fa-user-tag"></i>
                              <p>Cartera de clientes</p>
                            </Link>
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}



              </div>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
}
