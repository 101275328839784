import React, { useEffect, useState } from "react";
import { CollectionSheetForm } from "../fragments.accounting/CollectionSheetForm";
import { CollectionSheetList } from "../fragments.accounting/CollectionSheetList";
import { getCurrentDate } from "../../../configs/helpers";
import {
    fetchedAllGangs,
    fetchedAllSellers,
    fetchedSalesBySeller,
} from "../../../apis/apis.accounting/apisCash";
import { toast } from "react-toastify";

const initialQuestionState = {
    userID: "0",
    gangID: "0",
    searchDate: getCurrentDate("-"),
    selectedGangs: [],
};
const initialCashFlowState = {
    transactionDate: getCurrentDate("-"),
    description: "",
    type: "E",
    operationID: 0,
    total: 0,
    userID: 0,
};
export function CollectionSheetPage() {
    const [questionState, setQuestionState] = useState(initialQuestionState);
    const [gangs, setGangs] = useState([]);
    const [sellers, setSellers] = useState([]);
    const [sales, setSales] = useState([]);
    const [cashFlow, setCashFlow] = useState(initialCashFlowState);
    const [cashFlowSet, setCashFlowSet] = useState([]);
    function handleChange(e) {
        setQuestionState({ ...questionState, [e.target.name]: e.target.value });
    }
    const handleCheckboxChange = (gangID) => {
        if (questionState.selectedGangs.includes(gangID)) {
            // Si ya está seleccionado, lo eliminamos
            setQuestionState({
                ...questionState,
                selectedGangs: questionState.selectedGangs.filter(
                    (id) => id !== gangID
                ),
            });
        } else {
            // Si no está seleccionado, lo agregamos
            setQuestionState({
                ...questionState,
                selectedGangs: [...questionState.selectedGangs, gangID],
            });
        }
    };

    const getAllGangs = async () => {
        try {
            const response = await fetchedAllGangs();
            setGangs(response);
            console.log(response);
        } catch (error) {
            throw error;
        }
    };

    const getAllSellers = async () => {
        try {
            const response = await fetchedAllSellers();
            setSellers(response);
        } catch (error) {
            throw error;
        }
    };

    const getAllSalesBySeller = async () => {
        // if (questionState.selectedGangs.length === 0) {
        //     toast.warning("Seleccione al menos una ruta");
        //     return;
        // }
        if (questionState.userID === "0") {
            toast.warning("Seleccione un vendedor");
            return;
        }
        try {
            const response = await fetchedSalesBySeller(questionState);
            setSales(response);
        } catch (error) {
            throw error;
        }
    };

    useEffect(() => {
        getAllGangs();
        getAllSellers();
    }, []);
    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Hoja de cobranza</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <a href="#">Finanzas</a>
                                </li>
                                <li className="breadcrumb-item active">
                                    Hoja de cobranza
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="card card-default">
                        <div className="card-header"></div>
                        <div className="card-body">
                            <div className="d-flex  align-items-end">
                                <div className="form-group">
                                    <label htmlFor="searchDate1">Fecha</label>
                                    <input
                                        type="date"
                                        className="form-control"
                                        id="searchDate1"
                                        name="searchDate"
                                        value={questionState.searchDate}
                                        onChange={handleChange}
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="userID1">Vendedor</label>
                                    <select
                                        className="form-control"
                                        value={questionState.userID}
                                        onChange={handleChange}
                                        name="userID"
                                        id="userID1"
                                    >
                                        <option value={"0"} disabled>
                                            Elegir...
                                        </option>
                                        {sellers?.map((g, i) => (
                                            <option key={i} value={g.sellerID}>
                                                {g.sellerName}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="  px-2">
                                    <h6 className="  ">
                                        Selecciona los grupos:
                                    </h6>
                                    {gangs.map((gang) => (
                                        <div
                                            key={gang.gangID}
                                            className="custom-control custom-checkbox custom-control-inline"
                                        >
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id={`gang${gang.gangID}`}
                                                value={gang.gangID}
                                                checked={questionState.selectedGangs.includes(
                                                    gang.gangID
                                                )}
                                                onChange={() =>
                                                    handleCheckboxChange(
                                                        gang.gangID
                                                    )
                                                }
                                            />
                                            <label
                                                className="custom-control-label"
                                                htmlFor={`gang${gang.gangID}`}
                                            >
                                                {gang.gangName}
                                            </label>
                                        </div>
                                    ))}
                                    <p className="small">
                                        Grupos seleccionados:{" "}
                                        {questionState.selectedGangs.length ||
                                            "Ninguno seleccionado"}
                                    </p>
                                </div>

                                <button
                                    className="btn btn-success my-3 float-right"
                                    onClick={getAllSalesBySeller}
                                >
                                    Filtrar
                                </button>
                            </div>

                            <div className="table-responsive">
                                <CollectionSheetList
                                    sales={sales}
                                    setCashFlow={setCashFlow}
                                    setCashFlowSet={setCashFlowSet}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <CollectionSheetForm
                setCashFlow={setCashFlow}
                cashFlow={cashFlow}
                cashFlowSet={cashFlowSet}
                setCashFlowSet={setCashFlowSet}
                getAllSalesBySeller={getAllSalesBySeller}
            />
        </>
    );
}
